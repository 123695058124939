.xray {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.xrayBgBlur {
  filter: blur(5px);
}

.xrayTopBarInline {
  padding-left: var(--mpr-2);
  padding-right: var(--mpr-2);
  padding-bottom: 25px;
  border-bottom: 1px solid var(--fill-Color);
}

.xrayScrollableBody {
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: relative;
}

.moreQuestions {
  margin-top: auto;
}

.moreQuestion {
  display: grid;
  gap: var(--mpr-2);
  margin-top: var(--mpr-2);
}

.moreQuestion .xrayQuestion {
  padding: var(--mpr-2);
  background-color: var(--fill-Dark-Color);
  border-radius: var(--mpr-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.xrayMoreQuestionText {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.moreQuestion .xrayQuestion:hover {
  background-color: var(--fill-Color-Hover);
  cursor: pointer;
}

.ActiveMoreQuestion {
  background-color: var(--primary-Color-Opacity) !important;
}

.xrayBody {
  margin-bottom: var(--mpr-2);
}

.xrayBody ul {
  display: grid;
  gap: var(--mpr-2);
  font-weight: 300;
  padding-left: var(--mpr-2);
}

.xrayQuotes {
  display: grid;
  gap: var(--mpr-2);
}

.xrayQuotes h3 {
  font-weight: bold !important;
}

.xrayQuotes p {
  font-size: 1rem;
}

.xrayQuotes .xrayQuote {
  display: grid;
  gap: var(--mpr-mini);
}

.xrayOverlay {
  top: 75px !important;
  height: calc(100% - 75px) !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.xrayOverlayTwo {
  top: 37px !important;
  height: calc(100% - 37px) !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

/* ----------------- Links ----------------- */

.xrayLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--mpr-2);
  height: 100%;
}

.xrayLinkCard {
  background-color: var(--primary-Color-Opacity);
  padding: var(--mpr-3);
  border-radius: var(--mpr-2);
  width: 100%;
  display: flex;
  gap: var(--mpr-2);
  overflow: hidden;
  cursor: pointer;
}

.xrayLinkCard a {
  color: var(--primary-Color);
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;
}

.xrayLinkCard h4 {
  font-weight: 300;
}

.xrayLinkCard img,
.xrayLinkCard .xrayLinkCardBgColor {
  width: 80px;
  height: 80px;
  background-size: cover;
  object-fit: cover;
  border-radius: var(--mpr-3);
}

.xrayCardContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: var(--mpr-3);
}

/* ----------------- Three Theme ----------------- */

.xrayThreeBody {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}

.xrayThreeFooter {
  padding: var(--mpr-2);
  background-color: #ebebeb;
  color: #a6a6a6;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: var(--mpr-large);
}

.xrayThreeFooter .kaFooter {
  padding: 0 !important;
}

.xrayThreeFooter > div:first-child {
  justify-self: start;
}

.xrayThreeFooter > div:nth-child(2) {
  justify-self: center;
}

.xrayThreeFooter > div:last-child {
  justify-self: end;
}

.xrayThreeFooter h5 {
  text-align: center;
  font-weight: 300 !important;
  padding: 0 !important;
}

.xrayThreeTopBarStarImage {
  margin-right: var(--mpr-2);
}

@media (max-width: 500px) {
  .xrayThreeBody {
    max-width: 100%;
  }

  .xrayThreeFooter {
    justify-content: center;
    grid-template-columns: 1fr;
    gap: var(--mpr-3);
  }

  .xrayThreeFooter > div:first-child {
    justify-self: center;
  }

  .xrayThreeFooter > div:last-child {
    justify-self: center;
  }

  .xrayThreeTopBarStarImage {
    margin-right: var(--mpr-3);
  }
}

/* ----------------- Arora Animation ----------------- */
.xrayThreeTopBar {
  position: relative;
  overflow: hidden;
}

.aroraAnimation {
  position: absolute;
  filter: blur(80px);
  opacity: 0.7;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  z-index: 1;
}

.aroraAnimation1 {
  background: #005cb1;
  top: -20%;
  left: 60%;
}

.aroraAnimation2 {
  background: #648fff;
  top: -80%;
  left: 25%;
}

.aroraAnimation3 {
  background: #bc7cee;
  top: -70%;
  left: -10%;
}

/* ----------------- Terminologies ----------------- */

.terminologiesContainer {
  width: 100%;
  border-radius: var(--mpr-3);
  border: 1px solid var(--fill-Dark-Color);
  overflow: hidden;
}

.terminologiesTable {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}

.terminologiesTable colgroup col:first-child {
  width: 30% !important;
}

.terminologiesTable colgroup col:last-child {
  width: 70% !important;
}

.terminologiesTable th {
  border: 1px solid var(--fill-Dark-Color);
  padding: var(--mpr-3);
  background-color: var(--primary-Color);
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 2;
}

.terminologiesTable td {
  border: 1px solid var(--fill-Dark-Color);
  padding: var(--mpr-3);
  text-align: left;
}

.terminologiesBody {
  max-height: 500px;
  overflow-y: auto;
  display: block;
}

.terminologiesBody .terminologiesTable {
  border-top: none;
}

.terminologiesTable tr:hover {
  background: var(--primary-Color-Opacity);
}

/* Reduce table height on mobile screens */
@media (max-width: 576px) {
  .terminologiesBody {
    max-height: 350px;
  }
  .xrayBody ul {
    padding-left: var(--mpr-1-2);
  }
}

@media (max-width: 480px) {
  .terminologiesTable colgroup col:first-child {
    width: 40% !important;
  }

  .terminologiesTable colgroup col:last-child {
    width: 60% !important;
  }
}
@media (max-width: 360px) {
  .terminologiesTable colgroup col:first-child {
    width: 45% !important;
  }

  .terminologiesTable colgroup col:last-child {
    width: 55% !important;
  }
}
