.eng {
  border-radius: inherit;
  background-color: var(--background-Color);
  width: 100%;
  height: 100%;
  color: var(--text-Color);
}

.darkTheme .eng .flipIcon {
  filter: brightness(0) invert(1);
}

.engBody {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
}

.engTopBar {
  margin-bottom: var(--mpr-1-2);
}

.engTopbarDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mpr-2);
}

.engTopbarLogoDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--mpr-3);
}

.engTopbarLogoDiv p {
  opacity: 0.5;
  font-size: 0.75rem;
}

.engTopbarLogoDiv img {
  height: 100%;
  height: var(--logo-height-small);
}

.engTitleAndDesc {
  display: grid;
  gap: var(--mpr-3);
}

.engTitleAndDesc div,
.engTitleAndDesc span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--mpr-mini);
}

.engTitleAndDesc span h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.engTitleAndDesc p {
  opacity: 0.5;
}

.engInputMainDiv {
  flex-grow: 1;
  display: grid;
  gap: var(--mpr-2);
}

.engInputBar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--mpr-1-2);
  border-radius: var(--mpr-3);
  background-color: var(--fill-Color);
  gap: var(--mpr-2);
  position: relative;
  min-height: 50px;
}

.engInputBar:hover {
  background-color: var(--primary-Color-Opacity);
}

.engInputBar h5 {
  font-weight: 500;
  z-index: 1;
}

.engInputBar .engInputText {
  font-weight: 300 !important;
}

.engInputBarContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--mpr-2);
}

.engInputBarContentData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--mpr-2);
  z-index: 2;
}

.engBgProgressBar {
  transition: all 1s cubic-bezier(0.79, 0.33, 0.14, 0.53) !important;
  position: absolute;
  /* Changed from relative to absolute */
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  border-radius: inherit;
  --engProgressBarColor: var(--fill-Dark-Color);
  background-color: var(--engProgressBarColor);
}
