.defaultEndScreen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--mpr-1);
}

.darkTheme .defaultEndScreen .flipIcon {
  filter: brightness(0) invert(1);
}

.defaultEndScreen .flipIcon {
  position: absolute;
  top: var(--mpr-1-2);
  right: var(--mpr-1-2);
}

.defaultEndScreen p {
  opacity: 0.5;
}

.endScreenSocials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--mpr-2);
  border-radius: var(--mpr-2);
  background-color: var(--primary-Color-Opacity);
}

.endScreenSocials div {
  cursor: pointer;
}

.endscreenVerifyIcon {
  width: 40px;
}
