.FeedbackContainer {
  background-color: var(--primary-Color-Opacity);
  padding: var(--mpr-2);
  border-radius: var(--mpr-3);
  display: grid;
  gap: var(--mpr-mini);
  width: 100%;
}

.Disclaimer {
  font-weight: 700;
}

.FeedbackContainer .RatingsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedbackTwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--mpr-3);
}

.feedbackTwo .ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--mpr-mini);
}

@media (max-width: 400px) {
  .feedbackTwo {
    flex-direction: column-reverse;
  }

  .ratings {
    flex-wrap: wrap;
  }

  .feedbackTwoDisclaimer {
    text-align: center;
  }
}
