.carouselTwo {
  border-radius: inherit;
  background-color: var(--background-Color);
  width: 100%;
  height: 100%;
}

.carouselTwoHeading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 24px 16px;
  border-bottom: 2px solid #e9e9e9;
}

.carouselTwoHeading h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: rgb(102, 102, 102);
}

.carouselTwoList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.carouselTwoList li {
  border-bottom: 2px solid rgba(233, 233, 233, 0.3);
  padding: 16px;
}

.carouselTwoList li:last-child {
  border-bottom: none;
}

.carouselTwoList li a {
  text-decoration: none;
  font-size: 0.93rem;
  font-weight: 600;
  width: 100%;
  display: block;
  color: rgb(102, 102, 102);
  margin-bottom: 3px !important;
}

.carouselTwoList li span {
  font-size: 0.8rem;
  text-transform: capitalize !important;
  color: rgb(102, 102, 102);
}

.carouselTwoCta {
  padding: 16px;
  border-top: 2px solid #e9e9e9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.carouselTwoCta span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselTwoCta span:hover {
  text-decoration: underline !important;
  color: var(--primary-Color) !important;
}

.carouselTwoCta span * {
  color: var(--primary-Color);
  font-weight: 600;
  font-size: 0.93rem;
  text-decoration: none;
}
