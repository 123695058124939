.endScreen {
  border-radius: inherit;
  background-color: var(--background-Color);
  width: 100%;
  height: 100%;
  color: var(--text-Color);
}

.endScreenBody {
  width: 100%;
  height: 100%;
  padding: var(--mpr-1);
  border-radius: inherit;
  position: relative;
}
