@media (max-width: 500px) {
  .xrayInlineMobileFour {
    flex: 1;
    display: flex;
    gap: var(--mpr-2);
    padding: 0 var(--mpr-1-2) var(--mpr-1-2) var(--mpr-1-2);
  }

  .xrayInlineMobileBodyFour {
    display: flex;
    flex-direction: column;
  }

  .xrayInlineQuestionsFour {
    width: 100%;
  }

  .xrayInlineQuestionsFour .xrayOverlayInlineDesktop {
    top: 0px !important;
    height: 100% !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  .xrayInlineQuestionsFour .moreQuestions {
    margin-top: auto;
    width: 100%;
    margin-bottom: var(--mpr-mini);
  }

  .xrayInlineQuestionsFour .moreQuestion {
    gap: var(--mpr-3);
    padding: 0px;
  }

  .xrayInlineMobileAnswersFour {
    width: 100%;
  }
}

/* ---------------------- summary section ---------------------- */

.xrayInlineMobileAnswersFour .summaryListContainer ul {
  gap: 0;
  font-weight: 400;
  line-height: 1.5;
}
