.sliderPoll {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  padding: var(--mpr-1-2);
}

.sliderPoll .engInputMainDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0px;
}

.sliderPoll .sliderPollFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--mpr-2);
  margin: var(--mpr-3);
  margin-bottom: 0;
}

.sliderPollFooter p {
  opacity: 1 !important;
  font-weight: 400;
}

.sliderPollSecondOption {
  text-align: right;
}

.sliderPollSlider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderPollSlider .slider {
  width: 95%;
  overflow: visible !important;
}

.sliderPollSlider .slider::-moz-range-thumb {
  max-width: 20px !important;
  max-height: 20px !important;
}

.sliderPollSlider {
  position: relative;
}

.sliderPollSlider span {
  position: absolute;
  height: 2px;
  width: 90%;
  background-color: var(--fill-Color);
}

.sliderPollMarkerDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: twinkle 2s infinite; /* Apply the twinkle animation */
}

@keyframes twinkle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slider {
  -webkit-appearance: none !important;
  height: 1px !important;
  background: transparent !important;
  z-index: 1 !important;
}

.slider:hover {
  background: transparent !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px !important;
  height: 20px !important;
  border: 0 !important;
  background-color: var(--primary-Color) !important;
  border: 3px solid rgba(255, 255, 255, 0.7) !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.slider::-moz-range-thumb {
  width: 30px !important;
  height: 30px !important;
  border: 0 !important;
  background-color: var(--primary-Color) !important;
  border: 3px solid rgba(255, 255, 255, 0.7) !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.sliderPollChartParent {
  flex-grow: 1;
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: -15px;
  height: 100%;
  min-height: 210px;
}

.sliderPollChart {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.sliderPollChart {
  width: 90%;
}

.sliderPollChart canvas {
  padding: 0px;
  margin: 0px;
}
