.moreQuestion .xrayQuestionFourDesktop {
  padding: 7px var(--mpr-3);
  background-color: #e9e9e9;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}

.moreQuestion .xrayQuestionFourDesktop:hover {
  background-color: var(--fill-Color-Hover);
  cursor: pointer;
}

.xrayInlineQuestionsFour .moreQuestion {
  gap: var(--mpr-3);
}

.xrayMoreQuestionTextFourDesktop {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.ActiveMoreQuestionFourDesktop {
  background-color: #000 !important;
  color: #fff !important;
}

.xrayInlineDesktopFour {
  flex: 1;
  display: flex;
  gap: var(--mpr-2);
  padding: 0 var(--mpr-1-2) var(--mpr-1-2) var(--mpr-1-2);
}

.xrayInlineQuestionsFour {
  width: 30%;
}

.xrayInlineDesktopAnswersFour {
  width: 70%;
}

.xrayOverlayInlineDesktopFour {
  top: 0px !important;
  height: 100% !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

/* ---------------------- terminology section ---------------------- */

.terminologiesListContainerFour {
  width: 100%;
  padding: 0 var(--mpr-3) 0 0;
  max-height: 300px;
  overflow-y: auto;
}

.terminologyItemFour {
  display: flex;
  justify-content: start;
  align-content: center;
  padding-bottom: var(--mpr-2);
  margin-bottom: var(--mpr-2);
  border-bottom: 1px solid var(--fill-Dark-Color);
}

.terminologyItemFour:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.termFour {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5px;
  width: 30%;
  color: var(--text-secondary) !important;
}

.definitionFour {
  font-size: 1rem;
  color: var(--text-secondary);
  width: 70%;
  padding: 0 var(--mpr-3);
}

.terminologyItemFourMobile {
  flex-direction: column;
}

.termFourMobile {
  width: 100%;
  font-weight: bold;
}

.definitionFourMobile {
  width: 100%;
}

/* ---------------------- summary section ---------------------- */

.xrayInlineDesktopAnswersFour .summaryListContainer ul {
  gap: 0;
  font-weight: 400;
  line-height: 1.5;
}
