.kaCollapseIcon {
  opacity: 0.8;
  cursor: pointer;
  position: fixed;
  top: var(--mpr-2);
  right: var(--mpr-2);
}

.kaOne,
.kaTwo,
.kaSidebarOne,
.kaSidebarThree,
.kaInlineFAQ {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-Color);
}

.kaSidebarOne,
.kaSidebarThree {
  justify-content: center;
}

/* -------------------- Top Bar -------------------- */

.kaTopBarOne {
  padding: var(--mpr-1-2);
  display: grid;
  gap: var(--mpr-3);
  width: 100%;
}

.kaTopBarOne h3 {
  font-weight: 400 !important;
}

.kaInlineTopBarTopText {
  transition: 0.1s ease-in-out;
}

.kaTopBarOne:hover .kaInlineTopBarTopText {
  opacity: 1 !important;
  transition: 0.1s ease-in-out;
}

.kaTopBarOne:hover .kaInlineTopBarTopText img {
  animation: blink 1.3s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* -------------------- Input -------------------- */

.kaInputDivOne {
  /* display: grid;
  gap: var(--mpr-2);
  grid-template-columns: 0.1fr 4fr 1fr;
  grid-template-rows: 1fr;
  align-items: flex-end;
  border: 1px solid red; */

  display: grid;
  grid-template-columns: 10fr 1.5fr;
  gap: var(--mpr-3);
  align-items: center;
  justify-content: center;
  border-radius: var(--mpr-3);
  transition: all 0.2s ease-in-out;
}

.kaInputFieldOne {
  display: grid;
  grid-template-columns: 0.2fr 10fr;
  align-items: center;
  justify-content: center;
  background: #e9e9e9;
  border-radius: var(--mpr-3);
}

.kaInputFieldOne:hover {
  background: #dfdfdf;
}

.kaInputDivOne input {
  margin-bottom: 0px !important;
  font-size: 16px;
  background-color: transparent;
}

.kaInputDivOne button {
  height: 42px;
}

/* Template 2 */

.kaInputDivTwo {
  width: 100%;
  display: grid;
  grid-template-columns: 10fr 0.5fr;
  grid-template-rows: 0.5fr;
  grid-column-gap: var(--mpr-3);
  grid-row-gap: var(--mpr-3);
  background-color: #e6eff2;
  padding: var(--mpr-mini);
  border-radius: var(--mpr-3);
  border: 1px solid var(--fill-Color);
  align-items: center;
}

.kaInputDivTwo input {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-bottom: 0px !important;
  font-size: 16px;
}

.kaInputDivTwo input:hover {
  background-color: transparent;
}

.kaInputDivTwo button {
  height: 100%;
}

/* Template 3 */

.kaInputDivInlineFAQ {
  width: 100%;
  display: grid;
  grid-template-columns: 0.1fr 10fr 0.5fr;
  grid-template-rows: 0.5fr;
  grid-column-gap: var(--mpr-3);
  grid-row-gap: var(--mpr-3);
  border-radius: 25px;
  align-items: center;
  padding: 1px 6px;
}

.kaInputDivInlineFAQ input {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-bottom: 0px !important;
  font-size: 16px;
}

.kaInputDivInlineFAQ input::placeholder {
  color: var(--primary-Color);
  font-size: 0.75rem;
  opacity: 0.7;
}

.kaInputDivInlineFAQ input:hover {
  background-color: transparent;
}

.kaInputDivInlineFAQ button {
  background-color: var(--background-Color);
  border-radius: 50px;
  border: none;
}

.kaInputDivSidePanelOne {
  display: grid;
  grid-template-columns: 0.1fr 10fr 0.5fr;
  gap: var(--mpr-3);
  align-items: center;
  justify-content: center;
  padding: var(--mpr-3);
  border-radius: var(--mpr-3);
  transition: all 0.2s ease-in-out;
}

.kaInputDivSidePanelOne:hover {
  background-color: var(--primary-Color-Opacity) !important;
  transition: all 0.2s ease-in-out;
}

.kaInputDivSidePanelOne input {
  margin-bottom: 0px !important;
  background-color: transparent !important;
  font-size: 16px !important;
}

.kaInputDivSidePanelOne input::placeholder {
  opacity: 0.5;
  font-size: 0.9rem;
}

.kaInputDivSidePanelOne button {
  height: 100%;
  border: none;
  border-radius: var(--mpr-mini);
}

.kaInputDivSidePanelOneAISvg {
  width: 20px;
  height: 20px;
  margin-left: var(--mpr-mini);
}

.kaInputDivInlineOneAISvg {
  width: 18px;
  height: 18px;
  margin-left: var(--mpr-3);
}

@media (max-width: 500px) {
  .kaInputDivSidePanelOne {
    padding: var(--mpr-mini);
    border-radius: var(--mpr-mini);
  }
}

/* -------------------- FAQ -------------------- */

.kaFaqOneParent {
  width: 100%;
  padding: 0px var(--mpr-1-2);
  height: 100%;
  padding-bottom: var(--mpr-3);
}

.kaFaqOne {
  background-color: var(--fill-Color);
  padding: var(--mpr-2);
  border-radius: var(--mpr-3);
  width: 100%;
}

.kaFaqOne ul li {
  background-color: var(--background-Color);
  padding: var(--mpr-3);
  border-radius: var(--mpr-3);
  list-style: none;
  cursor: pointer;
  margin-top: var(--mpr-2);
}

.kaFaqOne ul li:hover {
  opacity: 0.8;
}

/* Template Two */

.kaTwo .kaFaqOne {
  background-color: #e6eff2;
  border-radius: var(--mpr-mini);
}

@media (max-width: 500px) {
  .kaTwo .kaFaqOneParent {
    padding: 0px;
  }
}

.kaFaqSidebarOneCard {
  transition: all 0.1s ease-in-out;
  max-width: 300px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--mpr-3);
  border-radius: var(--mpr-mini);
  margin-right: var(--mpr-1-2);
  position: relative;
}

.kaFaqSidebarOneCardDelete {
  transition: all 0.1s ease-in-out;
  background-color: oklch(0.637 0.237 25.331);
  width: 20px;
  height: 20px;
  border-radius: var(--mpr-mini);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 0.8rem;
  position: absolute;
  top: var(--mpr-mini);
  right: var(--mpr-mini);
  cursor: pointer;
}

.kaFaqSidebarOneCardDelete:hover {
  background-color: oklch(0.704 0.191 22.216);
  transition: all 0.1s ease-in-out;
}

.kaFaqSidebarOneCardDelete:active {
  background-color: oklch(0.577 0.245 27.325);
  transition: all 0.1s ease-in-out;
}

.kaFaqSidebarOneCard:hover {
  transition: all 0.1s ease-in-out;
  background-color: var(--primary-Color-Opacity) !important;
  cursor: pointer;
}

.kaFaqSidebarOneCard * {
  font-weight: 400 !important;
  font-size: 0.9rem;
}

.kaFaqSidebarOneFAQs {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: var(--mpr-3);
}

.kaFaqSidebarOneFAQsGradient {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
  background: linear-gradient(to right, white, transparent);
}

.kaFaqSidebarOneFAQs .gradient-left {
  left: 0;
  width: 2.5rem;
}

.kaFaqSidebarOneFAQs .gradient-right {
  right: 0;
  background: linear-gradient(to left, white, transparent);
  width: 2.5rem;
}

@media (max-width: 768px) {
  .gradient-left,
  .gradient-right {
    width: 5rem;
  }
}

/* -------------------- Body -------------------- */

.kaBody {
  padding: 0px var(--mpr-1-2);
  gap: var(--mpr-2);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

/* -------------------- Footer -------------------- */

.kaFooter {
  padding: var(--mpr-3) var(--mpr-1-2);
  text-align: left;
  width: 100%;
}

.kaFooter a {
  color: var(--primary-Color);
}

.kaFooter h5 {
  font-weight: 400;
  text-align: left;
}

.kaTwo .kaFooter {
  opacity: 0.5;
}

.kaTwo .kaFooter a {
  color: var(--text-Color);
}

.kaInlineFAQ .kaFooter {
  padding: 0px;
}

.kaInlineFAQ .kaFooter h5 {
  opacity: 0.7;
  text-align: center;
}

.kaSidebarThreeBottomChat {
  width: 100%;
  padding: var(--mpr-1-2);
  display: grid;
  gap: var(--mpr-2);
  border-top: 1px solid var(--fill-Color);
}

.inlineFAQBottomChat {
  width: 100%;
  padding: var(--mpr-3);
  display: grid;
  gap: var(--mpr-2);
}

/* -------------------- Answers -------------------- */

.kaAnswers {
  display: flex;
  flex-direction: column;
  gap: var(--mpr-2);
  padding-bottom: var(--mpr-3);
}

.kaAnswerMainDiv {
  display: grid;
  gap: var(--mpr-3);
}

.kaAnswerMainDiv,
.kaAnswerMainDiv *,
.kaAnswersQuestion,
.kaAnswersQuestion *,
.kaReference,
.kaReference * {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.kaAnswerMainDiv * {
  line-height: 1.5;
}

.kaSidebarThree .kaAnswerMainDiv {
  margin-bottom: var(--mpr-3);
}

.kaAnswers .kaAnswerHeading {
  margin-bottom: var(--mpr-mini);
}

.kaAnswers h3 {
  font-weight: 400 !important;
  word-break: break-word;
}

.kaAnswers p {
  font-size: 1.1rem;
  font-weight: 400;
}

.kaAnswers * {
  font-size: 1.1rem;
}

.kaAnswers ul,
.kaAnswers ol {
  display: grid;
  padding-left: var(--mpr-1-2);
}

.kaSidebarThree .kaAnswersQuestion,
.kaSidebarThree .kaAnswersAnswer,
.kaInlineFAQ .kaAnswersQuestion,
.kaInlineFAQ .kaAnswersAnswer {
  width: fit-content;
  border-radius: var(--mpr-2);
  padding: var(--mpr-2);
  max-width: 80%;
}

.kaInlineFAQ .kaAnswersQuestion h3,
.kaInlineFAQ .kaAnswersAnswer .kaAnswerMainDiv p {
  font-weight: 500 !important;
}

.kaInlineFAQ .kaAnswersQuestion,
.kaInlineFAQ .kaAnswersAnswer {
  width: fit-content;
  border-radius: var(--mpr-mini);
  padding: var(--mpr-3);
}

.kaInlineFAQ .kaAnswersAnswer * {
  font-size: 0.9rem;
}

.kaSidebarThree .kaAnswersQuestion,
.kaInlineFAQ .kaAnswersQuestion {
  align-self: flex-end;
  border-top-right-radius: 0px;
}

.kaSidebarThree .kaAnswersAnswer,
.kaInlineFAQ .kaAnswersAnswer {
  border-top-left-radius: 0px;
  background-color: var(--primary-Color-Opacity);
}

@media (max-width: 500px) {
  .kaSidebarThree .kaAnswersQuestion,
  .kaSidebarThree .kaAnswersAnswer,
  .kaInlineFAQ .kaAnswersQuestion,
  .kaInlineFAQ .kaAnswersAnswer {
    max-width: 95% !important;
  }
}

/* -------------------- Reference -------------------- */

.kaReference {
  padding-bottom: var(--mpr-3);
}

.kaReferenceHeading {
  margin-bottom: var(--mpr-3);
}

.kaReference ul {
  display: grid;
  padding-left: var(--mpr-1-2);
  gap: var(--mpr-3);
}

.kaReference ul * {
  font-size: 1rem;
}

.kaReference ul a {
  color: var(--primary-Color);
}

.kaReference .icon {
  margin-left: var(--mpr-mini);
  text-decoration: none;
}

.kaTwo .kaReference ul {
  padding-left: 0px;
  list-style: none;
}

.kaTwo .kaReference ul a {
  color: #009ee0;
  text-decoration: none;
}

.kaSidebarThree .kaReference ul a,
.kaInlineFAQ .kaReference ul a {
  color: var(--text-Color) !important;
}

.kaInlineFAQ .kaReference {
  background-color: var(--background-Color);
  padding: var(--mpr-3);
  border-radius: var(--mpr-mini);
  margin-top: var(--mpr-mini);
}

.kaInlineFAQ .kaReference ul {
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--mpr-2);
}

.kaInlineFAQ .kaReference ul a {
  text-decoration: none;
  font-size: 0.8rem;
  color: #3783d3 !important;
}

/* -------------------- Reference -------------------- */

.kaRatingsParent {
  padding-bottom: var(--mpr-3);
}

.kaRatingsOne {
  background-color: var(--primary-Color-Opacity);
  padding: var(--mpr-2);
  border-radius: var(--mpr-mini);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--mpr-mini);
}

.kaRatingsOne p {
  font-weight: 500;
}

.kaTwo .kaRatingsOne {
  background-color: #f2f2ed;
  padding: var(--mpr-3) var(--mpr-2);
}

.kaTwo .kaRatingsOne p {
  font-weight: 400;
  opacity: 0.6;
}

/* -------------------- Tutors -------------------- */

.kaTutors {
  padding-bottom: var(--mpr-3);
}

.kaTutorsHeading {
  margin-bottom: var(--mpr-3);
}

.kaSliderControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.kaSliderControls .prevButton,
.kaSliderControls .nextButton {
  position: absolute;
  width: fit-content;
  border-radius: 50%;
  padding: var(--mpr-mini) !important;
}

.kaSliderControls .prevButton {
  left: 10px;
  top: 30%;
}

.kaSliderControls .nextButton {
  right: 10px;
  top: 30%;
}

.kaSliderControls .prevButton:hover,
.kaSliderControls .nextButton:hover {
  opacity: 1;
}

.kaAllTutorialCards {
  display: flex;
  align-items: flex-start;
  gap: var(--mpr-2);
  overflow-x: auto;
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.kaTutorialCard {
  width: 100%;
  max-width: 300px;
  flex-shrink: 0;
  background-color: var(--primary-Color-Opacity);
  border-radius: var(--mpr-3);
  overflow: hidden;
  padding: var(--mpr-3);
  scroll-snap-align: start;
}

.kaAllTutorialCards::-webkit-scrollbar {
  height: 3px;
}

.kaAllTutorialCards::-webkit-scrollbar-thumb {
  background: var(--primary-Color);
  border-radius: var(--mpr-1);
}

.kaAllTutorialCards::-webkit-scrollbar-track {
  background: var(--primary-Color-Opacity);
}

.kaTutorialCard .kaTutorialCardProfilePicture {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: var(--mpr-mini);
  margin-bottom: var(--mpr-3);
}

.kaTutorialCardBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--mpr-mini);
  border-radius: var(--mpr-mini);
}

.kaTutorialCardBody p {
  font-weight: 400;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 55px;
}

.kaTutorialCardBody h3 {
  margin-bottom: var(--mpr-3);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--mpr-mini);
  font-weight: bolder;
}

.kaTutorialCardBody h4 span {
  font-weight: 400;
}

.kaTutorialCardBody .kaTutorialCardVerifyBadge {
  width: 21px;
}

.kaTutorialCardBody a {
  margin-top: var(--mpr-3);
  width: fit-content;
  border-radius: var(--mpr-mini);
  text-decoration: none;
}

/* --------------- ka Gated Inline ----------- */

.kaGatedInlineCard {
  width: 100%;
  display: flex;
  gap: var(--mpr-2);
  overflow: hidden;
  position: relative;
}

.kaGatedInlineCard h4 {
  font-weight: 300;
}

.kaGatedInlineCard img,
.kaGatedInlineCard .kaGatedInlineCardBgColor {
  width: 70px;
  height: 70px;
  background-size: cover;
  object-fit: cover;
  border-radius: var(--mpr-3);
}

.kaGatedInlineCardContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: var(--mpr-3);
}

.kaGatedInlineCardCloseIcon {
  background-color: var(--primary-Color-Opacity);
  border-radius: var(--mpr-mini);
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

/* -------------- KA Dea ------------- */

.kaDEA {
  display: flex;
  flex-direction: column;
  gap: var(--mpr-2);
  padding-bottom: var(--mpr-3);
}

.KaDeaTable {
  border-radius: var(--mpr-mini);
  overflow: hidden;
}

.KaDeaTable th {
  border: 1px solid var(--fill-Dark-Color);
  padding: var(--mpr-3);
  background-color: var(--fill-Color);
  text-align: left;
  color: black !important;
}

.KaDeaTable td {
  border: 1px solid var(--fill-Dark-Color);
  padding: var(--mpr-3);
  text-align: left;
}
