.dmgFullPage {
  background-color: var(--background-Color);
  height: 100%;
  width: 100%;
  border-radius: inherit;
  padding: var(--mpr-1-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dmgFullPage > div:nth-child(2) {
  flex: 10;
}

/* .dmgFullPage > div:first-child {
  flex: 10;
}

.dmgFullPage > div:last-child {
  flex: 0.5;
} */

.dmgFullPage .dmgFullPageFooter {
  width: 100%;
  padding: var(--mpr-1-2);
  padding-bottom: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dmgFullPage .dmgFullPageFooter * {
  font-weight: 400;
  font-size: 1rem;
}

.dmgFullPage .dmgFullPageFooter a {
  text-decoration: underline;
  color: var(--text-Color);
}

.dmgUser {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mpr-2);
}

.dmgMiddleBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--mpr-1);
}

.dmgMiddleBarData {
  display: grid;
  gap: 40px;
  width: 70%;
}

.dmgFAQs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--mpr-1-2);
}

.dmgFAQ {
  background-color: var(--fill-Color);
  max-width: 300px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--mpr-3);
  border-radius: var(--mpr-mini);
}

.dmgFAQ:hover {
  background-color: var(--fill-Color-Hover);
  cursor: pointer;
}

.dmgFAQ:active {
  background-color: var(--fill-Color);
}

.dmgFAQ * {
  font-weight: 400 !important;
  font-size: 0.9rem;
}

.DmgInputBox {
  width: 100%;
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-template-rows: 2fr;
  grid-column-gap: var(--mpr-1-2);
  grid-row-gap: var(--mpr-1-2);
  align-items: center;
}

.DmgInputBox input {
  margin-bottom: 0px !important;
  font-size: 16px;
}

.DmgInputBox button {
  height: 42px;
}

.DmgLogoTitle {
  text-align: center;
  display: grid;
  gap: var(--mpr-3);
}

.DmgLogoTitle h4 {
  font-size: 1.2rem;
}

.DmgLogoTitle h5 {
  font-size: 1rem;
  color: var(--primary-Color);
}

.darkTheme .DmgLogoTitle h5 {
  color: yellow !important;
  font-weight: 400;
}

.DmgLogoTitle img {
  height: 100%;
  height: 35px;
}

.DmgLogoTitle h4 {
  font-weight: 400;
}

.dmgBody .dmgTopBar .DmgLogoTitle {
  text-align: left;
}

.dmgBody {
  display: flex;
  flex-direction: column;
}

.dmgBody .dmgTopBar {
  display: grid;
  gap: var(--mpr-1-2);
}

.DmgScrollableDiv {
  margin-top: var(--mpr-1-2);
  padding-top: var(--mpr-1-2);
  border-top: 1px solid var(--fill-Color);
  overflow-y: auto;
  flex-grow: 1;
  height: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.DmgSuccessContainer,
.DmgAlternateQueriesContainer {
  display: grid;
  gap: var(--mpr-1-2);
}

.DmgScrollableDiv p,
.DmgScrollableDiv ul li {
  font-size: 1.1rem;
  font-weight: 400;
}

.DmgScrollableDiv h4 {
  font-size: 1.1rem;
}

.DmgScrollableDiv ul li span {
  font-weight: bolder;
}

.DmgAlternateQueries {
  background-color: var(--fill-Color);
  padding: var(--mpr-1-2);
  border-radius: var(--mpr-3);
  display: grid;
  gap: var(--mpr-1-2);
}

.DmgAlternateQueries p {
  background-color: var(--background-Color);
  padding: var(--mpr-3);
  border-radius: var(--mpr-mini);
  cursor: pointer;
}

.DmgAlternateQueries p:hover {
  opacity: 0.7;
}

.DmgAlternateQueries p:active {
  background-color: var(--fill-Color-Hover);
}

.DmgErrorContainer {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  padding: var(--mpr-2);
  border-radius: var(--mpr-mini);
  color: black !important;
}

.DmgTable {
  border-radius: var(--mpr-mini);
  overflow: hidden;
}

.DmgTable th {
  border: 1px solid var(--fill-Dark-Color);
  padding: var(--mpr-3);
  background-color: var(--fill-Color);
  text-align: left;
  color: black !important;
}

.DmgTable td {
  border: 1px solid var(--fill-Dark-Color);
  padding: var(--mpr-3);
  text-align: left;
}

.DmgButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--mpr-3);
}

.DmgButtonContainer button {
  width: 150px;
  background-color: var(--fill-Color);
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--mpr-mini);
}

@media (max-width: 500px) {
  .dmgMiddleBarData {
    width: 100%;
  }

  .dmgFullPageFooter {
    padding: 0px;
  }
}
