@keyframes growProgressCounter {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--progressValue);
  }
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

div[role='progressCounter'] {
  --bg: #def;
  --pgPercentage: var(--progressValue);
  animation: growProgressCounter 3s 1 forwards;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background:
    radial-gradient(closest-side, var(--background-Color) 80%, transparent 0 99.9%, white 0),
    conic-gradient(var(--primary-Color) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(2rem / 5);
  color: var(--primary-Color);
}

.progressCounter span {
  counter-reset: percentage var(--progressValue);
  color: var(--text-Color);
  font-size: 0.7rem;
}
