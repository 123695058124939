.engagementFooter {
  text-align: left;
  width: 100%;
  margin-top: 0;
  padding: 0 var(--mpr-2) var(--mpr-2) var(--mpr-2);
}

.engagementFooter h5 {
  font-weight: 400;
  text-align: center;
}

.engagementFooter a {
  color: var(--primary-Color);
}
