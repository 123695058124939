.link {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
}

.link .ctaContent button {
  display: block !important;
}
