/* Message.css */

.message {
  z-index: 1000;
  position: absolute;
  top: -200px; /* Start above the view */
  left: 0;
  right: 0;
  transition: top 0.5s ease-in-out;
  padding: var(--mpr-2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.messageContent {
  color: white;
  width: 80%;
  text-align: center;
  padding: var(--mpr-1);
  border-radius: var(--mpr-2);
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.message .success {
  background-color: #4caf50;
}

.message .error {
  background-color: #f44336;
}

.message .warning {
  background-color: #ffb546;
}

.visible {
  top: 0;
}

.hidden {
  top: -200px; /* Adjust this value to match the initial top value of .message */
}
