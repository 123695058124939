.PowerdBy {
  padding: var(--mpr-3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--mpr-mini);
}

.PowerdBy span {
  font-weight: 500;
  opacity: 0.6;
}

.PowerdBy img {
  width: 50px;
  opacity: 0.6;
}
