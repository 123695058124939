.advertisement {
  border-top: 1px solid var(--fill-Dark-Color);
  display: flex;
  align-items: center;
  justify-content: stretch;
  min-height: 100px;
  max-height: 150px;
  flex: 2;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
  cursor: pointer;
}

.advertisementContent {
  display: flex;
  flex-direction: column;
  gap: var(--mpr-mini);
  width: 60%;
  padding: var(--mpr-2);
}

.advertisementAsset {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.advertisementAsset img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
