.chatBotLoaderContainer {
  background-color: var(--primary-Color-Opacity);
  width: fit-content;
  border-radius: var(--mpr-3);
  padding: var(--mpr-2);
  max-width: 80%;
  border-top-left-radius: 0px;
  margin-bottom: var(--mpr-3);
}

.chatBotLoader {
  width: 40px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, var(--text-Color) 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
  opacity: 0.7;
}

@keyframes l7 {
  33% {
    background-size:
      calc(100% / 3) 0%,
      calc(100% / 3) 100%,
      calc(100% / 3) 100%;
  }
  50% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 0%,
      calc(100% / 3) 100%;
  }
  66% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 100%,
      calc(100% / 3) 0%;
  }
}
