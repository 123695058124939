.mainFlipcard {
  background-color: transparent;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  /* transition: transform 0.6s; */
  border-radius: inherit;
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
  border-radius: inherit;
}

.flipCardFront {
  align-items: flex-start !important;
}

.flipCardBack {
  transform: rotateY(-180deg);
}
