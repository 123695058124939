.xrayInlineDesktop {
  flex: 1;
  display: flex;
  gap: var(--mpr-2);
  padding: var(--mpr-1-2);
}

.xrayInlineDesktopQuestions {
  width: 30%;
}

.xrayInlineDesktopAnswers {
  width: 70%;
}

.xrayInlineDesktopBody {
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
}

.xrayOverlayInlineDesktop {
  top: 0px !important;
  height: 100% !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
