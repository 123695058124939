.xrayInlineMobileBody {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.xrayInlineMobileQuestions {
  width: 100%;
  padding: var(--mpr-1-2);
  padding-bottom: var(--mpr-3);
}

.xrayInlineMobileQuestions .moreQuestion {
  gap: var(--mpr-3);
  margin-top: 0px;
}
.xrayInlineAnswer {
  max-height: 0;
  overflow: hidden;
  /* transition: max-height 0.3s ease; */
}

.xrayInlineAnswerActive {
  max-height: 600px;
  overflow: auto;
}

.xrayInlineAnswerDeactive {
  max-height: 0;
}

.xrayOverlayInlineMobile {
  top: 0px !important;
  height: 100% !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
