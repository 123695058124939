.newsletter,
.newsletterCenter {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.newsletterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletterBody {
  background-color: var(--background-Color);
  padding: var(--mpr-3);
  padding-top: var(--mpr-2);
  border-radius: var(--mpr-3);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--fill-Color);
  color: var(--text-Color);
}

.newsletterBodyBottom,
.newsletterBodyTop {
  width: 100%;
  display: grid;
  gap: var(--mpr-3);
}

.newsletterMainContent {
  padding: var(--mpr-3);
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.newsletterContent {
  color: white;
  width: 100%;
  display: grid;
  gap: var(--mpr-2);
}

.newsletterCenter .flipIcon,
.newsletterMainContent .flipIcon {
  position: absolute;
  top: var(--mpr-1-2);
  left: var(--mpr-1-2);
}

.newsletterContent img {
  width: var(--logo-height-large);
  border-radius: var(--mpr-mini);
  align-self: center;
  justify-self: center;
}

.newsletterContent button {
  display: none;
}

.newsletterCenterInputDiv {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: var(--mpr-3);
  grid-row-gap: var(--mpr-3);
  background-color: var(--background-Color);
  padding: 3px;
  border-radius: var(--mpr-3);
  border: 1px solid var(--fill-Color);
  align-items: center;
}

.newsletterCenterInputDiv input {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
}

.newsletterCenterInputDiv button {
  height: 100%;
}

.newsletterTermsAndConditions a {
  color: var(--primary-Color);
  text-decoration: none;
}

@media (max-width: 500px) {
  .newsletter {
    grid-template-columns: repeat(1, 1fr);
  }
  .newsletterContent button {
    display: block;
  }
  .newsletterBody {
    position: absolute;
    top: var(--mpr-3);
    left: var(--mpr-3);
    width: calc(100% - 2 * var(--mpr-3));
    height: calc(100% - 2 * var(--mpr-3));
  }
}

/* Left aligned classes */

.newsletterLeftAligned {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.description-container {
  border-left: 2px solid var(--primary-Color);
  height: 100%;
  padding-inline: 10px;
}

.highlightedBorder {
  background:
    linear-gradient(var(--background-Color), var(--background-Color)) padding-box,
    linear-gradient(to right, #5ab2ff, #f9d849) border-box;
  border-radius: var(--mpr-3);
  border: 1px solid transparent;
  box-shadow: 0px 0px 15px #5ab2ffb4;
}

.highlightedBorder input::placeholder {
  color: var(--text-Color);
}
