.spinner_container {
  position: relative;
  width: 1rem;
  height: 1rem;
}

.spinner_item {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  animation: spinner-spin 1s ease infinite;
  border-style: solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-width: 2px;
  border-bottom-color: #ffffff;
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0turn);
  }

  100% {
    transform: rotate(1turn);
  }
}
