.gateway {
  background-color: transparent;
  height: auto;
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  padding: var(--mpr-1-2);
}

.gatewaySelected {
  color: white !important;
}
