.xrayDefaultTopbar .xrayDefaultTopbarTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--mpr-1);
  padding-right: var(--mpr-1);
  padding-top: 25px;
  padding-bottom: 25px;
}

.xrayDefaultTopbar .xrayDefaultTopbarTitle h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.xrayDefaultTopbarTwo .xrayDefaultTopbarTitle {
  padding: 0px !important;
  justify-content: flex-end;
}
