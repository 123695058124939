.forms {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.formsBody {
  background-color: var(--background-Color);
  padding: var(--mpr-3);
  padding-top: var(--mpr-2);
  border-radius: var(--mpr-3);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--fill-Color);
  color: var(--text-Color);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.forms .formsBodyBottom {
  position: fixed;
  bottom: 11px;
  padding-bottom: 9px;
  width: 45%;
}

.formsBodyBottom,
.formsBodyTop {
  width: 100%;
  display: grid;
  gap: var(--mpr-3);
}

.formsBodyTop {
  flex: 1;
}

.formsBodyTop form {
  height: 100%;
  padding-bottom: var(--mpr-2);
  margin-bottom: var(--mpr-1);
}

.forms .formsBodyBottom,
.forms .topArrow {
  background-color: var(--background-Color);
  z-index: 1;
}

@media (max-width: 500px) {
  .forms {
    grid-template-columns: repeat(1, 1fr);
  }

  .formsBodyTop {
    flex: none;
  }

  .formsBody {
    position: absolute;
    top: var(--mpr-3);
    left: var(--mpr-3);
    width: calc(100% - 2 * var(--mpr-3));
    height: calc(100% - 2 * var(--mpr-3));
  }

  .forms .topArrow {
    position: fixed;
    top: 11px;
    padding-top: 9px;
    width: calc(100% - 2 * var(--mpr-1-2));
  }

  .forms .formsBodyBottom {
    width: calc(100% - 2 * var(--mpr-1-2));
  }

  .formsBodyTop form {
    margin-top: 45px;
    margin-bottom: 0px;
  }
}
