.poll {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
}

.poll .engTopBar {
  padding: var(--mpr-1-2);
}

.poll .engInputMainDiv {
  padding: 0px var(--mpr-1-2);
  padding-bottom: var(--mpr-1-2);
  /* border: 1px solid red;  */
}

.pollSelected {
  color: white !important;
}

.pollDisclaimer {
  padding-bottom: var(--mpr-3);
  opacity: 0.5;
}

.pollDisclaimer h5 {
  font-weight: 400;
  text-align: center;
}
