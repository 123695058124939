.cta {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-color: var(--fill-Dark-Color);
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ctaBody {
  width: 100%;
  height: 100%;
  padding: var(--mpr-3);
  border-radius: inherit;
  position: relative;
}

.gatedCtaOverlay {
  padding: var(--mpr-2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  /* Leave space from the top */
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: var(--mpr-3);
  background-color: rgba(0, 0, 0, 0.563);
  backdrop-filter: blur(5px);
  z-index: 100;
}

.ctaBackButton {
  background-color: rgba(0, 0, 0, 0.495);
  border-radius: var(--mpr-1);
  padding: 3px var(--mpr-2) 3px var(--mpr-3);
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ctaMainContent {
  padding: var(--mpr-3);
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.ctaContent {
  color: white;
  width: 100%;
  display: grid;
  gap: var(--mpr-2);
}

.ctaContent img {
  width: var(--logo-height-large);
  border-radius: var(--mpr-mini);
}

.ctaContent button {
  display: none;
}

.cta .flipIcon {
  position: absolute;
  top: var(--mpr-1-2);
  left: var(--mpr-1-2);
}

.ctaCenterContent {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.ctaCenterContent button {
  display: block !important;
}

.ctaContent a {
  color: var(--primary-Color) !important;
  text-decoration: underline;
  cursor: pointer !important;
}

@media (max-width: 500px) {
  .ctaContent button {
    display: block;
  }
}

/* left aligned classes */

.ctaLeftAlignedContent {
  width: 60% !important;
  margin: 30px;
}

.ctaLeftAlignedContent button {
  display: block !important;
}

@media (max-width: 500px) {
  .ctaLeftAlignedContent {
    width: fit-content !important;
    margin: 20px;
    gap: 15px !important;
  }
}

h2 {
  font-weight: 500;
}

.highlight {
  color: var(--primary-Color);
}
