.multimediaContainer {
  width: 100%;
  height: 100%;
  border-radius: var(--mpr-3);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.multimediaContainer .flipIcon {
  position: absolute;
  top: var(--mpr-1-2);
  right: var(--mpr-1-2);
  z-index: 1000;
  padding: var(--mpr-mini);
  border-radius: var(--mpr-3);
}

.multimediaStory {
  width: 100%;
  height: 100%;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  position: relative;
}

.multimediaSeeMoreCollapsed {
  background: var(--overlay-Gradient);
  padding: var(--mpr-2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.multimediaSeeMoreCollapsed a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--mpr-3);
}

.multimediaSeeMoreCollapsed * {
  text-decoration: none;
  color: white;
}

.multimediaSeeMore {
  width: 100%;
  height: 100%;
  padding: var(--mpr-2);
  background-color: #ffffffbb;
  backdrop-filter: blur(5px);
}

.multimediaCloseIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.multimediaCloseIcon span {
  cursor: pointer;
}

.multimediaStory video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
