.singlePageOne {
  width: 100%;
  height: 100%;
  padding: var(--mpr-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.singlePageOneTopBar {
  width: 70%;
  max-width: 1000px;
}

.singlePageOneTopBarHeading {
  text-align: center;
  display: grid;
  gap: var(--mpr-1-2);
}

.singlePageOneTopBarHeading h1 {
  font-weight: 400;
}

.singlePageOneTopBarHeading p {
  font-size: 1.1rem;
}

.singlePageOneTopBarGatedBanner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: var(--mpr-3);
  padding: var(--mpr-3) var(--mpr-2);
  border-radius: 0px 0px var(--mpr-3) var(--mpr-3);
}

.singlePageOneTopBarGatedBanner h5 {
  color: var(--primary-Color);
  font-weight: 400 !important;
}

/* --------- Form ------------  */

.singlePageOne .singlePageOneTopBarAISvg {
  width: 25px;
  height: 25px;
  margin-left: var(--mpr-mini);
}

.singlePageOne form {
  display: grid;
  grid-template-columns: 0.1fr 10fr 0.5fr;
  gap: var(--mpr-3);
  align-items: center;
  justify-content: center;
  padding: var(--mpr-3);
  border-radius: var(--mpr-3);
  margin-top: 80px;
  transition: all 0.2s ease-in-out;
}

.singlePageOneTopBarFormGated {
  border-radius: var(--mpr-3) var(--mpr-3) 0px 0px !important;
}

.singlePageOne form:hover {
  background-color: var(--primary-Color-Opacity) !important;
  transition: all 0.2s ease-in-out;
}

.singlePageOne input {
  margin-bottom: 0px !important;
  background-color: transparent !important;
  font-size: 16px !important;
}

.singlePageOne input::placeholder {
  opacity: 1;
  font-size: 1rem;
  color: var(--text-Color);
  font-weight: 300;
}

.singlePageOne button {
  background-color: var(--background-Color);
  height: 100%;
  width: 40px !important;
  border: none;
  border-radius: var(--mpr-mini);
}

/* --------- FAQ ------------  */

.singlePageOneFAQs {
  display: flex;
  flex-direction: column;
  gap: var(--mpr-1-2);
  position: relative;
  margin-top: 50px;
  width: 100%;
  max-width: 1000px;
}

.singlePageOneFAQsGradient {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
  background: linear-gradient(to right, white, transparent);
}

.darkTheme .singlePageOneFAQsGradient {
  background: linear-gradient(to right, #1a1a1a, transparent);
}

.singlePageOneFAQs .gradient-left {
  left: 0;
  width: 2.5rem;
}

.singlePageOneFAQs .gradient-right {
  right: 0;
  background: linear-gradient(to left, white, transparent);
  width: 2.5rem;
}

.darkTheme .singlePageOneFAQs .gradient-right {
  background: linear-gradient(to left, #1a1a1a, transparent);
}

.singlePageOneFAQDiv {
  padding: var(--mpr-3) var(--mpr-1-2);
  border-radius: 999px;
  cursor: pointer;
  height: fit-content;
  text-wrap: nowrap;
  margin-right: var(--mpr-2);
  transition: all 0.2s ease-in-out;
}

.singlePageOneFAQDiv:hover {
  background-color: var(--primary-Color-Opacity) !important;
  transition: all 0.2s ease-in-out;
}

.singlePageOneFAQDiv p {
  font-size: 1rem;
}

.singlePageOneKAFooter {
  padding-top: var(--mpr-2);
  text-align: left;
  width: 100%;
}

.singlePageOneKAFooter h5 {
  font-weight: 400;
  text-align: center;
}

.singlePageOneKAFooter a {
  color: var(--primary-Color);
}

/* --------- Body ------------  */

.singlePageOneBody {
  flex: 1;
  margin-top: 30px;
  width: 100%;
  max-width: 1000px;
  overflow-y: auto;
}

.singlePageOneBody::-webkit-scrollbar {
  width: 0px;
}

.singlePageOneBodySingle {
  display: flex;
  flex-direction: column;
  gap: var(--mpr-1-2);
  overflow: hidden;
}

.singlePageOneBodyQuestion {
  font-size: 1.1rem !important;
  align-self: flex-end;
  width: fit-content;
  border-radius: var(--mpr-2);
  border-top-right-radius: 0px !important;
  padding: var(--mpr-2);
  max-width: 80%;
}

.singlePageOneDisclaimer {
  padding: var(--mpr-3);
  border-left: 3px solid var(--primary-Color);
  border-top-right-radius: var(--mpr-mini);
  border-bottom-right-radius: var(--mpr-mini);
}

.singlePageOneDisclaimer p {
  font-size: 0.9rem !important;
  font-weight: 400;
}

.singlePageOneAnswerDiv {
  display: grid;
  gap: var(--mpr-3);
  position: relative;
  max-width: 80% !important;
  background-color: #f9f9f9;
  border-radius: var(--mpr-2);
  border-top-left-radius: 0px !important;
  padding: var(--mpr-2);
}

.singlePageOneAnswerDiv li {
  margin-left: var(--mpr-2);
}

.darkTheme .singlePageOneAnswerDiv {
  background: var(--fill-Color);
}

.singlePageOneAnswerDiv .chatBotLoaderContainer {
  background: var(--fill-Color) !important;
}

.singlePageOneAnswerDiv .lockedMarkdown {
  filter: blur(6px);
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
  height: 100%;
  min-height: 200px;
}

.singlePageOneAnswerDivLocked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: var(--mpr-2);
  padding: var(--mpr-1);
}

.singlePageOneAnswerDivLocked h3 {
  width: 50%;
}

.singlePageOneAnswerDiv {
  display: grid;
  gap: var(--mpr-3);
}

.singlePageOneAnswerDiv * {
  line-height: 1.5;
  font-size: 1.1rem !important;
}

.singlePageOneAnswerDiv strong {
  font-weight: 500;
}

.singlePageOneAnswerDiv,
.singlePageOneAnswerDiv *,
.singlePageOneBodyQuestion,
.singlePageOneBodyQuestion *,
.chatBotLoaderContainer,
.singlePageOneReference,
.singlePageOneReference * {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.singlePageOneFeedback {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: var(--mpr-3);
}

.singlePageOneFeedback p {
  font-weight: 500;
}

.singlePageOneReference {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--mpr-2);
}

.singlePageOneReference::-webkit-scrollbar {
  height: 0px;
}

.singlePageOneReferenceContainer button {
  transition: all 0.2s ease-in-out;
  width: fit-content;
  background-color: var(--background-Color);
  width: 35px !important;
  height: 35px !important;
  border-radius: 999px;
  border: 1px solid var(--primary-Color-Opacity);
}

.singlePageOneReferenceContainer button:hover {
  transition: all 0.2s ease-in-out;
  background-color: var(--primary-Color-Opacity);
}

.singlePageOneReferenceContainer button:active {
  opacity: 1 !important;
}

.singlePageOneReferenceCardParent {
  border-radius: var(--mpr-3);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--fill-Dark-Color);
  width: 300px;
  min-width: 300px;
  overflow: hidden;
  position: relative;
}

.singlePageOneReferenceCard {
  padding: var(--mpr-2);
  border-radius: var(--mpr-3);
  display: flex;
  flex-direction: column;
  gap: var(--mpr-3);
  width: 100%;
  height: 100%;
  position: relative;
}

.singlePageOneReferenceCard h5 {
  font-weight: 400;
  font-size: 0.9rem;
  height: 36px;
}

.singlePageOneReferenceCard a {
  background-color: var(--background-Color);
  font-size: 0.8rem;
  padding: 7px 14px;
  border-radius: 999px;
  overflow: hidden;
  text-wrap: nowrap;
  position: relative;
  color: #005ee8 !important;
  font-weight: 400;
}

.singlePageOneReferenceCardGradient {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
  right: 0;
  background: linear-gradient(to left, white, transparent);
  width: 5rem;
}

.darkTheme .singlePageOneReferenceCardGradient {
  background: linear-gradient(to left, #1a1a1a, transparent);
}

.singlePageOneBodyBottom {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.singlePageOneBodyBottom form {
  margin-top: var(--mpr-1);
}

@media (max-width: 768px) {
  .singlePageOneTopBar,
  .singlePageOneBody,
  .singlePageOneBodyBottom {
    width: 100%;
    max-width: 1000px;
  }

  .singlePageOneFAQs {
    width: 100%;
    max-width: 1000px;
  }

  .singlePageOne input::placeholder {
    font-size: 0.9rem;
  }

  .gradient-left,
  .gradient-right {
    width: 5rem;
  }

  .singlePageOneAnswerDivLocked h3 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .singlePageOneTopBar {
    width: 100%;
    max-width: 1000px;
  }
  .singlePageOneTopBar,
  .singlePageOneFAQs,
  .singlePageOneBody,
  .singlePageOneBodyBottom {
    width: 100%;
  }

  .singlePageOneTopBarHeading {
    gap: var(--mpr-2);
  }

  .singlePageOneTopBarHeading h1 {
    font-size: 1.5rem;
  }

  .singlePageOneTopBarHeading p {
    font-size: 0.9rem;
  }

  .singlePageOne .singlePageOneTopBarAISvg {
    width: 20px;
    height: 20px;
  }

  .singlePageOne form {
    padding: var(--mpr-mini);
    border-radius: var(--mpr-mini);
    margin-top: 25px;
  }

  .singlePageOneFAQDiv p {
    font-size: 0.85rem;
  }

  .singlePageOneFAQs {
    margin-top: 30px;
  }

  .singlePageOneBody {
    margin-top: 20px;
  }

  .singlePageOneBodyQuestion {
    font-size: 1rem !important;
  }

  .singlePageOneBodySingle {
    gap: var(--mpr-3);
  }

  .singlePageOneDisclaimer {
    border-left: 2px solid var(--primary-Color);
  }
  .singlePageOneDisclaimer p {
    font-size: 0.8rem !important;
  }

  .singlePageOneAnswerDiv * {
    font-size: 1rem !important;
  }

  .singlePageOneAnswerDiv,
  .singlePageOneBodyQuestion {
    max-width: 95% !important;
  }

  .singlePageOneAnswerDiv li {
    margin-left: var(--mpr-3);
  }

  .singlePageOneBodyBottom form {
    margin-top: var(--mpr-2);
  }

  .singlePageOneFeedback {
    flex-direction: column;
    align-items: flex-start;
  }
}
