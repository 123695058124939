.registration,
.registrationCenter {
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
}

.registrationCenter {
  padding: var(--mpr-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.registrationMainContent {
  padding: var(--mpr-3);
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.registrationContent {
  color: white;
  width: 100%;
  display: grid;
  gap: var(--mpr-2);
}

.registrationCenter .flipIcon {
  position: absolute;
  top: var(--mpr-1-2);
  left: var(--mpr-1-2);
}

.registrationCenter h6 {
  position: absolute;
  bottom: var(--mpr-1-2);
}

.registrationContent > .flipIcon {
  justify-self: flex-start !important;
  align-self: flex-start !important;
}

.registrationContent img {
  width: 100px;
  border-radius: var(--mpr-mini);
  align-self: center;
  justify-self: center;
}

.registrationContent a {
  color: var(--primary-Color) !important;
  text-decoration: underline;
  cursor: pointer !important;
}

.registrationCenterContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
