* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* transition: all 0.2s ease-in-out; */
  /* border: 1px solid red; */
}

html {
  overflow: hidden;
}

:root {
  --text-Color: #000;
  --primary-Color: #385ef9;
  --background-Color: white;
  --primary-Color-Opacity: #385ff911;
  --fill-Color: #ebebeb;
  --fill-Color-Hover: #dfdfdf;
  --fill-Dark-Color: #7575753a;
  --overlay: #00000073;
  --overlay-Gradient: linear-gradient(to bottom, transparent 0%, black 100%);
  --carousel-overlay: rgba(0, 0, 0, 0.7);
  --mpr-large: 60px;
  --logo-height-small: 20px;
  --logo-height-large: 100px;
  --mpr-1: 30px;
  --mpr-1-2: 20px;
  --mpr-2: 15px;
  --mpr-3: 10px;
  --mpr-mini: 5px;
  --outer-border-radius: 10px;
  --shine-border-color1: #7c3aed;
  --shine-border-color2: #fe8fb5;
  --shine-border-color3: #ffbe7b;
}

.flipcardLayout {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.flipcardLayout .swiper-button-prev,
.flipcardLayout .swiper-button-next {
  scale: 0.7;
}

.opacity05 {
  opacity: 0.5;
}

.firstLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.secondLine {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.thirdLine {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bridgedCard {
  width: 100%;
  height: 100%;
  border: 1px solid var(--fill-Color);
  overflow: hidden;
  position: relative;
  transition: height 0.2s ease 0s;
}

.divider {
  height: 1px;
  width: 100%;
  border-radius: 50px;
  background: var(--fill-Color);
}

.primaryTextColor {
  color: var(--primary-Color) !important;
}

.whiteIcon {
  filter: brightness(0) invert(1);
}

.flipcardLayout button,
.flipcardLayout .button {
  width: 100%;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--primary-Color);
  border-radius: var(--mpr-3);
  padding: var(--mpr-3);
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--fill-Color);
}

.flipcardLayout button:active,
.flipcardLayout .button:active {
  opacity: 0.8;
}

.flipcardLayout p {
  font-size: 0.8rem;
  font-weight: 300;
}

.flipcardLayout h3 {
  font-weight: 500;
  font-size: 1.05rem;
}

.flipcardLayout input,
.flipcardLayout textarea {
  width: 100%;
  outline: none;
  border: none;
  padding: var(--mpr-3);
  background: var(--fill-Color);
  border-radius: var(--mpr-3);
  border: 0.5px solid transparent;
  resize: none;
  overflow: hidden;
}

.inputError.error input,
.inputError.error textarea {
  border: 0.5px solid #d24847;
}

.errorText {
  color: #d24847;
  margin-bottom: var(--mpr-mini);
  font-size: 0.7rem;
}

.flipcardLayout label,
.flipcardLayout input[type='checkbox'] {
  width: fit-content;
}

.flipcardLayout label {
  cursor: pointer;
}

.flipcardLayout label input {
  margin-right: var(--mpr-3);
}

.flipcardLayout input:hover,
.flipcardLayout select:hover,
.flipcardLayout textarea:hover {
  background: var(--fill-Color-Hover);
}

.flipcardLayout form {
  width: 100%;
}

.flipcardLayout form h5 {
  font-weight: 300;
  font-size: 0.75rem;
  margin-bottom: var(--mpr-3);
}

.flipcardLayout form h5 span {
  color: red;
}

.flipcardLayout form input,
.flipcardLayout form select,
.flipcardLayout form label,
.flipcardLayout textarea {
  margin-bottom: var(--mpr-3);
}

.flipcardLayout ::placeholder {
  font-size: 0.8rem;
  opacity: 0.9;
  font-weight: 400;
}

.topArrow span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.flipIcon {
  cursor: pointer;
}

.darkTheme .topArrow .icon {
  filter: brightness(0) invert(1);
}

.darkTheme input,
.flipcardLayout select,
.flipcardLayout textarea {
  color: var(--text-Color);
}

.topArrow {
  display: none;
}

.cardLeftTitleBorder {
  position: absolute;
  height: 20px;
  border-radius: 0px 100px 100px 0px;
  left: 0px;
  border: 2.8px solid var(--primary-Color);
}

.flipcardLayout .relative {
  position: relative !important;
}

.flipcardLayout .description {
  padding: var(--mpr-mini);
  border-radius: var(--mpr-mini);
}

.flipcardLayout .chip {
  background: #385ff99b;
  padding: var(--mpr-mini) var(--mpr-3);
  border-radius: var(--mpr-1);
}

.flipcardLayout .googleLogin {
  border: 0.5px solid var(--fill-Dark-Color) !important;
  padding: var(--mpr-3) !important;
  border-radius: var(--mpr-3) !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--background-Color) !important;
  color: var(--text-Color) !important;
}

.flipcardLayout .customScrollBar::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}

.flipcardLayout .customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f17c;
}

.flipcardLayout .customScrollBar::-webkit-scrollbar-thumb {
  background: #8fb1e558;
  border-radius: 50px;
}

.flipcardLayout .customScrollBar::-webkit-scrollbar-thumb:hover {
  background: var(--primary-Color);
}

.flipcardLayout .googleAdsParent {
  width: 100%;
}

@media (max-width: 500px) {
  .topArrow {
    display: block;
  }
}

.starAnimation {
  animation: starAnimation 2s infinite;
}

@keyframes starAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* -------------------- Border -------------------- */

.shineBorder {
  padding: 2px;
}

.shineBorder::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: var(--outer-border-radius);
  padding: 3px;
  background-image: radial-gradient(
    transparent,
    transparent,
    var(--shine-border-color1),
    var(--shine-border-color2),
    var(--shine-border-color3),
    transparent,
    transparent
  );
  background-size: 400% 400%;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  will-change: background-position;
  pointer-events: none;
  animation: shine 14s linear infinite alternate;
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 100% 50%;
  }
}
