.ctaCarousel {
  border-radius: inherit;
  background-color: var(--background-Color);
  width: 100%;
  height: 100%;
  color: var(--text-Color);
}

.ctaCarouselBody {
  width: 100%;
  height: 100%;
  padding: var(--mpr-1-2);
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
}

.darkTheme .ctaCarouselTopBar .flipIcon {
  filter: brightness(0) invert(1);
}

.ctaCarouselTopBar {
  margin-bottom: var(--mpr-1-2);
}

.ctaCarouselTopbarDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mpr-2);
}

.ctaCarouselTopbarLogoDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--mpr-3);
}

.ctaCarouselTopbarLogoDiv p {
  opacity: 0.5;
  font-size: 0.75rem;
}

.ctaCarouselTopbarLogoDiv img {
  height: 100%;
  height: var(--logo-height-small);
}

.ctaCarouselTitleAndDesc {
  display: grid;
  gap: var(--mpr-3);
}

.ctaCarouselTitleAndDesc p {
  opacity: 0.5;
}

.ctaCarouselContent {
  flex-grow: 1;
  /* overflow-x: scroll; */
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--mpr-1-2);
}

@media (max-width: 500px) {
  .ctaCarouselContent {
    flex-direction: column;
    gap: 10px;
  }
}

.ctaCarouselCard {
  flex: 1;
  min-width: 0;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-color: var(--fill-Dark-Color);
  border-radius: var(--mpr-3);
}

.ctaCarouselCardBody {
  border-radius: inherit;
  background: var(--carousel-overlay);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: var(--mpr-3);
  width: 100%;
  cursor: pointer;
}

.dark .ctaCarouselCardBody * {
  color: white;
}

.ctaCarouselCardBodyContent {
  width: 100%;
}

.ctaCarouselCardBodyContent span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ctaCarouselCardBodyContent span p {
  font-weight: 500;
  cursor: pointer !important;
}

@media (max-width: 500px) {
  .ctaCarouselCard {
    width: 100%;
    min-height: 150px;
    flex: none;
  }

  .ctaCarouselCardBody {
    background: var(--carousel-overlay);
  }
}
